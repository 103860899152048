import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const IndexPage = () => {

    const [email, setEmail] = React.useState('');
    const [isSubmitted, setSubmitted] = React.useState(false);

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Join the Closed Beta
                    </h1>
                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        Right now, the Irishman Cloud is in a closed beta as we test out all of the current features we are looking to offer. If you would like a chance to join the beta, please enter your email below.
                    </p>
                    <div class="block w-full flex mt-8 gap-1">
                        <input class="w-full border-1 rounded bg-white" type="email" value={email} name="Email" placeholder="Email Address" required onChange={(e) => { setEmail(e.target.value) }} />
                        <button class="bg-primary text-white font-bold p-4 rounded" type="submit" value="Subscribe" name="subscribe" onClick={async () => {

                            const result = await addToMailchimp(email);

                            if (result) {

                                setSubmitted(true);

                            }

                        }}>Submit</button>
                    </div>
                </div>
            </div>
        </section>
        {isSubmitted && <div class="fixed left-0 top-0 h-screen w-screen grid h-screen place-items-center">
            <section class="bg-white rounded-3xl shadow-2xl z-50 h-min">
                <div class="p-8 text-center sm:p-12">
                    <p class="text-sm font-semibold uppercase tracking-widest text-pink-500">
                        You are now on the list
                    </p>
                    <h2 class="mt-6 text-3xl font-bold">
                        If you get picked for the beta, we will email you!
                    </h2>
                    <a
                        class="mt-8 inline-block w-full rounded-full bg-pink-600 py-4 text-sm font-bold text-white shadow-xl"
                        href="/"
                    >
                        Go Home
                    </a>
                </div>
            </section>
        </div>}
    </Layout >
}

export const Head = () => <Seo title="Our Team" />

export default IndexPage
